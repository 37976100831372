<template>
  <div class="player-control" @click.stop ref="playerControl">
    <div class="left">
      <img
        v-if="!paused"
        src="../../../assets/img/live/stop.png"
        alt=""
        @click.stop="togglePlay"
        class="stop"
      />
      <img v-else src="../../../assets/img/live/play.png" alt="" @click="togglePlay" class="stop" />
    </div>
    <div class="right">
      <img
        src="../../../assets/img/live/refush.png"
        alt=""
        @click.stop="refush"
        class="fullscreen"
        style="margin-right: 6.4vw"
        v-if="props.refush"
      />
      <img
        src="../../../assets/img/live/fullscreen.png"
        alt=""
        @click.stop="fullscreen"
        class="fullscreen"
        v-if="!props.fullScreenIshow"
      />
      <!-- 小屏播放 -->
      <img
        src="@/assets/img/live/icon3.png"
        alt=""
        class="fullscreen"
        v-else
        @click="emit('cancelFullScreen')"
      />
    </div>
  </div>
</template>
<script setup name="playerControl">
import { ref, defineEmits } from "vue";
const props = defineProps({
  paused: {
    type: Boolean,
    default: true,
  },
  refush: {
    type: Boolean,
    default: true,
  },
  fullScreenIshow: {
    type: Boolean,
    default: true,
  },
});
const playerControl = ref();
const emit = defineEmits(["togglePlay", "fullscreen", "refush"]);
function togglePlay() {
  emit("togglePlay");
}
function fullscreen() {
  if (getMobileOperatingSystem() == "Android") {
    document.getElementById("PlayVideo").appendChild(playerControl.value);
  }

  emit("fullscreen");
}
function refush() {
  emit("refush");
}
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return "iOS";
  } else if (userAgent.match(/Android/i)) {
    return "Android";
  } else {
    return "unknown";
  }
}
</script>
<style scoped>
.player-control {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10.93vw;
  padding: 0 3.2vw;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: all 0.3s ease;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stop,
.fullscreen {
  /* width: 6.4vw;
  height: 6.4vw; */
  width: 24px;
  height: 24px;
}
</style>
