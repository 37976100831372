<template>
  <div class="image_wrap video-image_wrap" :class="isLeft ? 'isLeft' : 'isRight'">
    <div class="img_box">
      <img :src="props.item.snapshotUrl" fit="cover" class="img_wrap" />
      <img src="@/assets/img/icon/living.png" alt="" class="icon" @click="livingVideo" />
      <div class="videoSecond">
        {{ getvideoSecond() }}
      </div>
    </div>

    <van-overlay :show="videoShow" @click.stop="close">
      <div class="video_box" @click.stop>
        <div
          ref="videoRef"
          class="player"
          id="PlayVideo"
          :class="'player' + props.index"
          style="width: 100%; height: auto; position: relative"
        ></div>
        <PlayerControl
          @togglePlay="togglePlay"
          @fullscreen="fullscreen"
          @refush="refush"
          :paused="paused"
          :refush="false"
        />
      </div>
    </van-overlay>
  </div>
</template>
<script setup>
import DPlayer from "dplayer";
import PlayerControl from "../../live/components/playerControl.vue";
import { ref } from "vue";
const props = defineProps({
  isLeft: Boolean,
  item: Object,
  index: {
    type: Number,
    default: 0,
  },
});
const videoShow = ref(false);
const videoPlay = ref();
function livingVideo() {
  videoShow.value = true;
  setTimeout(() => {
    initPlayer();
  }, 100);
}
function close() {
  dp.destroy();
  videoShow.value = false;
}
function getvideoSecond() {
  let str = "";
  if (props.item.videoSecond == 0) {
    str = "";
  } else if (props.item.videoSecond < 60) {
    str =
      "00:" + (props.item.videoSecond < 10 ? "0" + props.item.videoSecond : props.item.videoSecond);
  } else if (props.item.videoSecond < 3600) {
    let m = parseInt(props.item.videoSecond / 60);
    let s = parseInt(props.item.videoSecond % 60);

    str = "00:" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);
  } else if (props.item.videoSecond < 86400) {
    let h = parseInt(props.item.videoSecond / 3600);
    let min = props.item.videoSecond - h * 3600;
    let m = parseInt(min / 60);
    let s = parseInt(min - m * 60);
    str = (h < 10 ? "0" + h : h) + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);
  }

  return str;
}
let dp = null;
const paused = ref(false);
function initPlayer() {
  dp = new DPlayer({
    // container: document.getElementById("PlayVideo"),
    container: document.getElementsByClassName("player" + props.index)[0],
    autoplay: true,
    loop: false,
    volume: 50,
    video: {
      url: props.item.videoUrl,
      pic: props.item.snapshotUrl,

      type: "auto",
    },
  });
  dp.on("pause", () => {
    paused.value = false;
  });

  dp.on("play", () => {
    paused.value = true;
  });
}
function togglePlay() {
  dp.toggle();
}
function fullscreen() {
  dp.fullScreen.request("browser");
}
</script>
<style scoped>
.image_wrap {
  display: flex;
  align-items: center;
}
.img_box {
  width: 42.66vw;
  height: 42.66vw;
  position: relative;
}
.img_wrap {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
  margin-left: 2.66vw;
}
.icon {
  width: 8.53vw;
  height: 8.53vw;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -2vw;
  cursor: pointer;
  transform: translateY(-50%);
}

.videoSecond {
  font-size: 12px;
  position: absolute;
  right: 6px;
  bottom: 6px;
  color: #fff;
  font-weight: 600;
}
.isRight {
  margin-right: 10px;
}
.video_box {
  width: 100%;
  height: auto;
  position: relative;
}
#videoPlay {
  width: 100%;
  height: auto;
}
.van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style>
.dplayer-controller {
  opacity: 0;
}
.dplayer-mobile-play {
  display: none !important;
}
.dplayer-notice-list {
  display: none;
}
</style>
