<template>
  <message-bubble :isMine="isMine" :message="message">
    <img :src="filesCdnDomain + props.message.imgPath" alt="" />
  </message-bubble>
</template>
<script setup>
import TencentCloudChat from "@tencentcloud/chat";
import { computed } from "vue";
import { useStore } from "vuex";
import MessageBubble from "./messageBubble";
const store = useStore();
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const props = defineProps({
  isMine: Boolean,
  message: Object,
});
</script>

<style scoped>
img {
  width: 30.4vw;
  height: 30.4vw;
  display: block; border-radius: 4px;
}
</style>
