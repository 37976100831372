<template>
  <div class="text_wrap">
    <div class="bg">
      {{ props.item.nick }} 领取了{{ _data.pocketName }}的
      <span>红包</span>
    </div>
  </div>
</template>
<script setup>
import { defineProps, watchEffect, ref } from "vue";
const props = defineProps({
  item: Object,
});
const _data = ref();
watchEffect(() => {
  _data.value = JSON.parse(props.item.payload.data);
});
</script>
<style scoped>
.text_wrap {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.bg {
  padding: 4px 8px;
  color: #9CA3AF;
  text-align: center;
  font-family: PingFang SC;
  font-size: 13px;
  font-weight: 400;
}
span {
  font-weight: 400;color: #EDA150; 
}
</style>
