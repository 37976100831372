<template>
  <div class="message-send-box-wrapper" @click.stop>
    <div class="prohibition" v-if="props.isNo">您已经不是该群的成员了</div>
    <div class="prohibition" v-else-if="props.allProhibitioValue">管理员已开启全体禁言</div>
    <div class="prohibition" v-else-if="props.userProhibitioValue">您被禁言中</div>
    <div class="send-header-bar" v-else>
      <img src="@/assets/img/icon/emjio.png" alt="" class="emjio" @click="openEmoji" />

      <div
        class="editor"
        ref="divRef"
        placeholder="请输入消息..."
        contenteditable
        @keyup="handkeKeyUp"
        @keydown="handleKeyDown"
        @keypress.enter="enterUp"
        enterkeyhint="send"
        id="contenteditableDiv"
      ></div>
      <img src="@/assets/img/icon/add.png" alt="" class="add" @click.stop="openAdd" />
    </div>
    <div class="emoji_box" v-show="showEmoji">
      <div v-for="item in emojiName" class="emoji" :key="item" @click="chooseEmoji(item)">
        <img :src="emojiMap[item]" style="width: 36px; height: 36px" />
      </div>
    </div>
    <div class="add_box" v-show="showAdd">
      <div class="add_item" @click="handleSendImageClick">
        <img src="@/assets/img/icon/album.png" alt="" class="album" />
        <div class="text">相册</div>
      </div>
      <div class="add_item" @click="handleSendVideoClick">
        <img src="@/assets/img/icon/camera.png" alt="" class="album" />
        <div class="text">拍摄</div>
      </div>
    </div>
    <input
      type="file"
      id="imagePicker"
      ref="imagePicker"
      accept=".jpg, .jpeg, .png, .gif"
      @change="sendImage"
      style="display: none"
    />
    <input
      type="file"
      id="videoPicker"
      ref="videoPicker"
      accept=".mp4"
      capture="camera"
      @change="sendVideo"
      style="display: none"
    />
    <van-overlay :show="showDialog" @click.stop="">
      <SelectPerson
        :visible="showDialog"
        @close="closeSelectPerson"
        @handlePickUser="handlePickUser"
      />
    </van-overlay>
  </div>
</template>
<script setup>
import { chat } from "@/untils/tim";
import TencentCloudChat from "@tencentcloud/chat";
import SelectPerson from "../selectPerson.vue";
import { emojiMap, emojiName } from "@/untils/emojiMap";
import { showToast, showLoadingToast, closeToast } from "vant";
import { onMounted, onUnmounted, ref } from "vue";
import Bus from "@/untils/event-bus";
const showEmoji = ref(false);
const showAdd = ref(false);
const messageContent = ref();
const atUserIdArr = ref([]);
const imagePicker = ref();
const videoPicker = ref();
const sendLock = ref(false);
const props = defineProps({
  allProhibitioValue: {
    type: Boolean,
    default: false,
  },
  userProhibitioValue: {
    type: Boolean,
    default: false,
  },
  isNo: {
    type: Boolean,
    default: false,
  },
});
function closeSelectPerson() {
  showDialog.value = false;
}
function handlePickUser(user) {
  if (user) {
    replaceAtUser(user);
    // user.value = user;
  }
  showDialog.value = false;
}
function openEmoji() {
  showEmoji.value = !showEmoji.value;
  showAdd.value = false;
}
function openAdd() {
  showAdd.value = !showAdd.value;
  showEmoji.value = false;
}
function enterUp(event) {
  event.preventDefault();
  if (!divRef.value.innerText) return;
  sendMsgBtn();
}
function handleSendImageClick() {
  showAdd.value = false;
  imagePicker.value.click();
}
function handleSendVideoClick() {
  showAdd.value = false;
  videoPicker.value.click();
}
//发送图片
function sendImage() {
  showLoadingToast({
    duration: 0,
    forbidClick: true,
    message: "上传中…",
  });
  const message = chat().createImageMessage({
    to: localStorage.getItem("imChatId"),
    conversationType: localStorage.getItem("imChatType"),
    payload: {
      file: document.getElementById("imagePicker"), // 或者用event.target
    },
    onProgress: (percent) => {},
  });
  chat()
    .sendMessage(message)
    .then((res) => {
      closeToast();
      Bus.$emit("sendImMsg", [res.data.message]);
      imagePicker.value.value = null;
    })
    .catch((imError) => {
      closeToast();
    });
}
//发送视频
function sendVideo() {
  showLoadingToast({
    duration: 0,
    forbidClick: true,
    message: "上传中…",
  });
  const message = chat().createVideoMessage({
    to: localStorage.getItem("imChatId"),
    conversationType: localStorage.getItem("imChatType"),
    payload: {
      file: document.getElementById("videoPicker"), // 或者用event.target
    },
    onProgress: (percent) => {},
  });

  chat()
    .sendMessage(message)
    .then((res) => {
      closeToast();
      Bus.$emit("sendImMsg", [res.data.message]);
      videoPicker.value.value = null;
    })
    .catch((imError) => {
      closeToast();
    });
}
function sendMsgBtn() {
  if (sendLock.value) return;
  sendLock.value = true;
  let arr = document.querySelectorAll(".at-button");
  let obj;
  arr.forEach((element) => {
    obj = JSON.parse(element.datasetuser);
    if (obj.id == "allUser") {
      obj.id = TencentCloudChat.TYPES.MSG_AT_ALL;
    }
    atUserIdArr.value.push(obj.id);
    obj = {};
  });

  var resultArr;
  //采用filter()方法过滤掉数组中重复的元素,filter()方法中传入一个过滤函数作为参数.
  resultArr = atUserIdArr.value.filter(function (item, index, self) {
    //indexOf返回的是arr中的第一个元素的索引值,所以下面语句过滤掉了arr中重复的元素.
    return self.indexOf(item) == index;
  });
  atUserIdArr.value = resultArr;
  //console.log(resultArr);
  let message;
  if (atUserIdArr.value.length > 0) {
    //@信息体
    message = chat().createTextAtMessage({
      to: localStorage.getItem("imChatId"),
      conversationType: TencentCloudChat.TYPES.CONV_GROUP,
      payload: {
        text: divRef.value.innerText,
        atUserList: atUserIdArr.value,
      },
    });
  } else {
    //普通文本消息
    message = chat().createTextMessage({
      to: localStorage.getItem("imChatId"),
      conversationType: localStorage.getItem("imChatType"),
      payload: {
        text: divRef.value.innerText,
      },
      needReadReceipt: localStorage.getItem("imChatType") == 'C2C'?true:false
    });
  }

  //发送消息
  chat()
    .sendMessage(message)
    .then((res) => {
      Bus.$emit("sendImMsg", [res.data.message]);
      divRef.value.innerHTML = "";
      atUserIdArr.value = [];
      sendLock.value = false;
    })
    .catch(() => {
      sendLock.value = false;
    });
}
const node = ref(); //获取节点
const user = ref(); //选中内容
const endIndex = ref(); //光标最后停留的位置
const queryString = ref();

const showDialog = ref(false);
//光标位置
function getCursorIndex() {
  const selection = window.getSelection();
  return selection.focusoffset; // 选择开始处 focusNode 的偏移量
}

//获取节点
function getRangeNode() {
  const selection = window.getSelection();
  return selection.focusNode; // 选择的结束节点
}

function showAt() {
  const node = getRangeNode();
  if (!node || node.nodeType !== Node.TEXT_NODE) return false;
  const content = node.textContent || "";
  const regx = /@([^@\s]*)$/;
  const match = regx.exec(content.slice(0, getCursorIndex()));
  return match && match.length === 2;
}

function getAtUser() {
  const content = getRangeNode().textContent || "";
  const regx = /@([^@\s]*)$/;
  const match = regx.exec(content.slice(0, getCursorIndex()));
  // console.log(match);
  if (match && match.length === 2) {
    return match[1];
  }

  return undefined;
}

function createAtButton(user) {
  const btn = document.createElement("span");
  btn.style.display = "inline-block";
  btn.datasetuser = JSON.stringify(user);
  btn.className = "at-button";
  btn.setAttribute("userid", `${user.id}`);
  btn.contentEditable = "false";
  btn.textContent = `@${user.name}`;
  const wrapper = document.createElement("span");
  wrapper.style.display = "inline-block";
  wrapper.contentEditable = "false";
  const spaceElem = document.createElement("span");
  spaceElem.style.whiteSpace = "pre";
  // spaceElem.textContent = "\u200b";
  spaceElem.innerHTML = "&nbsp;";
  spaceElem.contentEditable = "false";
  const clonedSpaceElem = spaceElem.cloneNode(true);
  // wrapper.appendChild(spaceElem);
  wrapper.appendChild(btn);
  // wrapper.appendChild(clonedSpaceElem);
  wrapper.appendChild(spaceElem);
  return wrapper;
}
function replaceString(raw, replacer) {
  return raw.replace(/@([^@\s]*)$/, replacer);
}
//插入@标签
function replaceAtUser(user) {
  const _node = node.value;
  if (_node && user) {
    const content = _node.textContent || "";
    const _endIndex = endIndex.value;
    const preSlice = replaceString(content.slice(0, _endIndex), "");
    // console.log(preSlice);
    const restSlice = content.slice(_endIndex);
    //console.log(_endIndex);
    const parentNode = _node.parentNode;
    const nextNode = _node.nextsibling;
    const previousTextNode = new Text(preSlice);
    const nextTextNode = new Text("\u200b"); // 添  宽字符
    const atButton = createAtButton(user);
    parentNode.removeChild(_node);
    //插在文本框中
    // console.log(nextNode);
    if (nextNode) {
      parentNode.insertBefore(previousTextNode, nextNode);
      parentNode.insertBefore(atButton, nextNode);
      parentNode.insertBefore(nextTextNode, nextNode);
    } else {
      parentNode.appendChild(previousTextNode);
      parentNode.appendChild(atButton);
      parentNode.appendChild(nextTextNode);
    }

    //重置光标的位置
    const range = new Range();
    const selection = window.getSelection();
    range.setStart(nextTextNode, 0);
    range.setEnd(nextTextNode, 0);
    selection.removeAllRanges();
    selection.addRange(range);
  }
}

//键盘抬起事件
function handkeKeyUp() {
  if (localStorage.getItem("imChatType") == "C2C") return;
  if (showAt()) {
    const _node = getRangeNode();
    const _endIndex = getCursorIndex();
    node.value = _node;
    endIndex.value = _endIndex;
    queryString.value = getAtUser();
    showDialog.value = true;
  } else {
    showDialog.value = false;
  }
}
function handleKeyDown(e) {
  if (showDialog.value) {
    if (e.code === "ArrowUp" || e.code === "ArrowDown" || e.code === "Enter") {
      e.preventDefault();
    }
  }
}

const divRef = ref();
function createInsterImgData(url) {
  const btn = document.createElement("span");
  btn.textContent = url;
  divRef.value.appendChild(btn);
}
function chooseEmoji(item) {
  createInsterImgData(item);
  showEmoji.value = false;
  //光标自动放在表情后面
  var esrc = document.getElementById("contenteditableDiv");
  var range = document.createRange();
  range.selectNodeContents(esrc);
  range.collapse(false);
  var sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}
onMounted(() => {
  window.addEventListener("click", function () {
    showEmoji.value = false;
    showAdd.value = false;
  });
});
onUnmounted(() => {
  window.addEventListener("click", function () {});
});
</script>

<style scoped>
.message-send-box-wrapper {
  background-color: #fff;
}
.prohibition {
  color: #9ca3af;
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 400;
  height: 16vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9fafb;
}
.send-header-bar {
  display: flex;
  align-items: center;
  padding: 8px;
  background: #f9fafb;
}
.emjio,
.add {
  width: 8.53vw;
  height: 8.53vw;
  margin: 4px 0;
}

.emoji_box {
  height: 52.8vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  background: #f9fafb;
  padding: 2.66vw;
}
.emoji {
  width: calc(100% / 7);
}
.add_box {
  padding: 6.13vw;
  height: 22.66vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #f9fafb;
}
.add_item {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 6.93vw;
}
.add_item img {
  width: 16vw;
  height: 16vw;
}
.text {
  color: #4b5563;
  font-family: PingFang SC;
  font-size: 3.2vw;
  margin-top: 7px;
  font-weight: 400;
}
.editor {
  width: calc(100% - 17.06vw);
  margin: 0 8px;
  /* display: flex;
  align-items: center; */
  text-align: left;
  caret-color: #d8ad6d;
}
.editor:empty:before {
  content: attr(placeholder);
  color: #ddd;
}
/*焦点时内容为空*/
.editor:focus:before {
  caret-color: #d8ad6d;
}
.editor:focus {
  border: none;
  outline: none;
  text-align: left;
  vertical-align: middle;
}
</style>
