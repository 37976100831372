<template>
  <message-bubble :isMine="isMine" :message="message">
    <div class="box">
      <img
        src="@/assets/img/icon/left_red.png"
        alt=""
        v-if="!isMine && !props.message.receiveRedPacket && !props.message.receiveRedPacketAll"
      />
      <img
        src="@/assets/img/icon/icon10.png"
        alt=""
        v-if="!isMine && (props.message.receiveRedPacket || props.message.receiveRedPacketAll)"
      />
      <div
        class="envelope"
        @click.stop="openDialogBtn"
        :class="[
          messagePosition,
          props.message.receiveRedPacket || props.message.receiveRedPacketAll ? 'receive' : '',
        ]"
      >
        <div class="top">
          <img
            src="@/assets/img/icon/icon9.png"
            alt=""
            v-if="props.message.receiveRedPacket || props.message.receiveRedPacketAll"
          />
          <img src="@/assets/img/icon/icon8.png" alt="" v-else />
          <div class="red_name">{{ _data.redPocket.title }}</div>
        </div>
        <div class="bottom">{{ !props.isMine ? props.message.nick : "你" }}的红包</div>
      </div>
      <img
        src="../../../assets/img/icon/right_red.png"
        alt=""
        v-if="isMine && !props.message.receiveRedPacket && !props.message.receiveRedPacketAll"
      />
      <img
        src="../../../assets/img/icon/icon12.png"
        alt=""
        v-if="isMine && (props.message.receiveRedPacket || props.message.receiveRedPacketAll)"
      />
    </div>
  </message-bubble>
</template>
<script setup>
import { computed, ref, watchEffect } from "vue";
import MessageBubble from "./messageBubble";
import { openDialog } from "@/untils/index";
const messagePosition = computed(() => (props.isMine ? "position-right" : "position-left"));
const props = defineProps({
  isMine: {
    type: Boolean,
  },
  message: {
    type: Object,
    required: true,
  },
});
function openDialogBtn() {
  openDialog();
}
const _data = ref();
watchEffect(() => {
  _data.value = JSON.parse(props.message.payload.data);
  // console.log(_data.value);
});
</script>

<style scoped>
.box {
  display: flex;
}
.box img {
  width: 6px;
  height: 22px;
  margin-top: 9px;
}

.envelope {
  border-radius: 4px;
  background: #eda150;
  width: 61.86vw;
  height: 26.13vw;
  padding: 0 9px;
}
.top {
  padding: 9px 0;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.top img {
  width: 10.66vw;
  height: 10.66vw;
}
.red_name {
  margin-left: 2.66vw;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  font-family: PingFang SC;
  font-size: 4.26vw;
  font-weight: 500;
  text-align: left;
}

.bottom {
  text-align: left;
  padding: 4px 0;
  color: rgba(255, 255, 255, 0.8);
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 400;
  line-height: 2vw; /* 100% */
}
.receive {
  background: #f8e2c6 !important;
}
</style>
<style>
.van-dialog__message {
  color: #000;
  text-align: center;
  font-family: PingFang SC;
  font-size: 4.26vw;
  font-weight: 500;
  line-height: 7.46vw;
}
</style>
